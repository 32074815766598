<template>

<app-content-box :is-empty="!data.length" :is-grid="true" empty-message="No prizes added yet." class="winners" title="Prizes" :transparent="data.length" icon="prize" :not-padded="true">

	<app-card v-for="item in data" :not-linked="true" :tab="(item.remaining > 1) ? 'x' + item.remaining.toString() : false" :key="item.id" :image="item.image" imageIcon="prize" :title="item.name" :subtitle="item.description" />

</app-content-box>

</template>

<script>

export default {
	
	name: 'prizes',

	props: ['data']

}

</script>

<style scoped>

.winners {
	grid-column: 1 / 5;
}

.winners >>> .box-content {
	min-height: 160px;
}

</style>
